<template>
    <div class="auth-wrapper auth-v1"
         style="padding: 0">
        <div class="auth-inner main-container-width">
            <v-row>
                <v-col cols="12"
                       sm="8"
                       md="6"
                       lg="12"
                       class="mx-auto">
                    <v-card flat
                            class="full-min-height">
                        <app-header :details="cardDetails"></app-header>

                        <v-stepper v-model="step"
                                   class="custom">
                            <v-stepper-items>
                                <div v-if="showAlert">
                                    <alert :message="alertMessage"
                                           :type="alertType"></alert>
                                </div>
                                <v-stepper-content step="1">
                                    <v-row>
                                        <v-col lg="7"
                                               class="mx-auto">
                                            <v-row>
                                                <v-col lg="10">
                                                    <div class="mx-5 pt-4">
                                                        <BackAction :text="text"
                                                                    :output="output" />
                                                    </div>
                                                    <p class="font-weight-semibold text-color-black mt-5 mx-11"
                                                       style="font-size: 17px">
                                                        {{ $t('Temporary suspend card') }}
                                                    </p>

                                                    <p class="font-weight-semibold text-color-black mt-9 mx-11"
                                                       style="font-size: 17px">
                                                        {{ $t('Enter 5-digit code sent to you') }}
                                                    </p>
                                                    <p class="text-color-black mx-11"
                                                       style="font-size: 12px">
                                                        {{ $t(`Please enter 5-digits verification code sent to you over
                                                        SMS`) }}
                                                    </p>

                                                    <v-row class="mx-8">
                                                        <v-col lg="8"
                                                               dir="ltr">
                                                            <v-otp-input v-model="lastFiveDigit"
                                                                         :rules="cards"
                                                                         :hide-details="true"
                                                                         @input="onOtpInput"
                                                                         @blur="onInputBlur"
                                                                         ref="otpInput"
                                                                         type="number"
                                                                         onkeydown="javascript: return event.keyCode == 69 ? false : true"
                                                                         length="5"></v-otp-input>
                                                            <div v-if="otpError"
                                                                 style="font-size: 12px"
                                                                 class="error-message">
                                                                {{ $t(otpErrorMessage) }}
                                                            </div>
                                                        </v-col>
                                                    </v-row>

                                                    <div class="mt-7 mx-11">
                                                        <timer :formattedTime="formattedTime"
                                                               :selectedLanguage="selectedLanguage"
                                                               @retry="sendOtpAgain"></timer>
                                                        <!-- <span :class="mr3Class" class="mx-11 referesh-btn">
                              <v-btn icon type="button" @click="getOtp" class="text-blue icon">
                                <v-icon>mdi-refresh</v-icon>
                              </v-btn>
                            </span>
                            <span class="text-blue" style="font-size: 14px">
                              {{ $t('Send Code again') }}
                            </span> -->
                                                    </div>
                                                    <v-row class="mx-6 mt-7">
                                                        <v-col lg="12">
                                                            <v-btn block
                                                                   color="primary"
                                                                   type="submit"
                                                                   :disabled="lastFiveDigit.length < 5"
                                                                   @click="nextStep"
                                                                   large>
                                                                {{ $t('Temporary suspend card') }}
                                                            </v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col lg="1"
                                               class="line-section">
                                            <v-row class="mt-3">
                                                <v-col class="divide"
                                                       style="height: 350px">
                                                    <v-divider class="my-16"
                                                               :vertical="true"></v-divider>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col lg="4"
                                               md="12"
                                               sm="12"
                                               cols="12">
                                            <v-row class="mt-16 mx-8">
                                                <v-col lg="8"
                                                       md="12"
                                                       sm="12"
                                                       cols="12">
                                                    <div class="text-center"
                                                         style="padding-top: 38px">
                                                        <div class="secure-img">
                                                            <img src="@/assets/images/logos/secure.png"
                                                                 alt="" />
                                                        </div>
                                                        <p class="font-weight-semibold text-color-black mb-2 mt-5">
                                                            {{ $t('Temporary suspend card') }}
                                                        </p>
                                                        <p class="font-weight-semibold text--primary mb-2 mt-5 security-text"
                                                           style="margin: 0 auto; font-size: 13px">
                                                            {{
                                                                $t(`Take control, temporarily suspend your card and reactivate
                                                            it at your convenience.`)
                                                            }}
                                                        </p>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-stepper-content>

                                <v-stepper-content step="2">
                                    <v-row class="pa-16">
                                        <v-col lg="12"
                                               class="text-center">
                                            <div class="secure-img">
                                                <img src="@/assets/images/Success.png"
                                                     alt="" />
                                            </div>

                                            <p class="mt-3 font-weight-semibold text-color-black"
                                               style="font-size: 22px">
                                                {{ $t('Card temporarily suspended') }}
                                            </p>
                                            <p style="font-size: 14px"
                                               class="mt-5">
                                                {{
                                                    $t(
                                                        `The Card ending with`
                                                    )
                                                }} {{ this.cardDetails.Last4Digits }}

                                                {{
                                                    $t(
                                                        `is Inactive`
                                                    )
                                                }}
                                            </p>

                                            <div>
                                                <!-- <v-btn color="primary"
                                                      @click="done"
                                                       class="submit"
                                                       type="submit"
                                                       large>
                                                    {{ $t('Done') }}
                                                                    </v-btn> -->
                                                <v-btn color="primary"
                                                       class="submit return"
                                                       type="submit"
                                                       to="/"
                                                       large>
                                                    {{ $t('Return to Home') }}
                                                </v-btn>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-stepper-content>
                            </v-stepper-items>
                        </v-stepper>
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import timer from '@/@core/timer.vue'
import AppHeader from '../test/AppHeader.vue'
import soapServices from '@/services/soap-services'
import store from '@/store'
import moment from 'moment'
import { DeviceUUID } from 'device-uuid'
import sharedServices from '@/services/shared-services'
import router from '@/router'
import alert from '@/views/alert.vue'
import { mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import soapErrorMessages from '@/@core/utils/soap-error-messages'
import BackAction from '@/@core/BackAction.vue';

import { getAnalytics, logEvent } from "firebase/analytics";

export default {
    components: {
        AppHeader,
        alert,
        BackAction,
        timer
        //     Cardmanagement,
    },

    data: () => ({
        analytics: getAnalytics(),
        step: 1,
        alertMessage: '',
        showTimer: false,
        time: 60,
        timerId: null,
        alertType: 'error',
        showAlert: false,
        otpError: false,
        otpErrorMessage: '',
        cards: [value => !!value || 'Last 5 digits are required.'],
        lastFiveDigit: '',
        selectedLanguage: 'en',
        cardDetails: null,
        text: 'Back to Cards',
        output: false,
    }),

    mounted()
    {
        let lang = localStorage.getItem('language')
        if (lang)
        {
            this.selectedLanguage = localStorage.getItem('language')
        }
        this.cardDetail = store.get('card/details')
        this.$route.params.cardType == 'primary'
            ? (this.cardDetail = store.get('card/details'))
            : (this.cardDetail = store.get('card/supplementory'))
        window.scrollTo(0, 0)
        this.cardDetails = store.get('card/details');
        this.sendOtpAgain()
    },
    created()
    {
        store.subscribe(mutation =>
        {
            if (mutation.type === 'accounts/SET_LANGUAGE')
            {
                this.selectedLanguage = mutation.payload;
            }
        })
    },
    computed: {
        formattedTime()
        {
            const minutes = Math.floor(this.time / 60)
            const seconds = this.time % 60
            return `${seconds.toString().padStart(2, '0')}`
        },
        mr3Class()
        {
            if (this.selectedLanguage === 'ar')
            {
                return 'ml-3'
            } else
            {
                return 'mr-3'
            }
        },
        passwordConfirmationRule()
        {
            return () => this.password === this.confirmPassword || 'Confirm Password must match'
        },
    },
    methods: {
        onOtpInput()
        {
            if (this.lastFiveDigit.length !== 5)
            {
                this.otpError = true
                this.otpErrorMessage = '5 digits code is required.';
                const inputElement = this.$refs.otpInput.$el.querySelector('input');
                if (inputElement)
                {
                    inputElement.focus(); // Move focus back into the input
                }
            } else
            {
                this.otpError = false
            }
        },
        onInputBlur()
        {
            if (!this.lastFiveDigit.length)
            {
                this.otpError = true
                this.otpErrorMessage = '5 digits code is required.'
            }
        },
        sendOtpAgain()
        {
            let obj = {
                correlationID: Math.floor(1000 + Math.random() * 9000).toString(),
                messageStamp: moment(new Date()).format('DD/MM/yyyy HH:mm:ss'),
                sysId: new DeviceUUID().get(),
                sysAuth: store.get('requestKeys/accessToken'),
                requestorID: localStorage.getItem('userName'),
                idSeed: sharedServices.getIdSeed(),
                custSerNo: store.get('requestKeys/ClientCode'),
                cardSserno: this.cardDetail.primeCardSerno,
            }

            soapServices.viewCardPin(obj).then(soapResp =>
            {
                if (soapResp?.RequestAuthCodeResponse?.RequestAuthCodeResult?.Result?.ErrorCode == '000')
                {
                    let alertMessage = soapResp.RequestAuthCodeResponse.RequestAuthCodeResult.Body.ResponseMsg
                    let errorCode = soapResp.RequestAuthCodeResponse.RequestAuthCodeResult.Result.ErrorCode
                    this.alertMessage = soapErrorMessages.get(errorCode, alertMessage)
                    this.alertType = 'success'
                    this.showAlert = true
                    setTimeout(() =>
                    {
                        this.showAlert = false
                    }, 5000)
                    this.showTimer = true
                    this.timerId = setInterval(() =>
                    {
                        this.time -= 1
                        if (this.time <= 0)
                        {
                            clearInterval(this.timerId)
                            this.showTimer = false
                            this.time = 60
                            this.timerId = null
                        }
                    }, 1000)
                } else
                {
                    let alertMessage = soapResp.RequestAuthCodeResponse.RequestAuthCodeResult.Resp.Result.ErrorDesc
                    let errorCode = soapResp.RequestAuthCodeResponse.RequestAuthCodeResult.Result.ErrorCode
                    this.alertMessage = soapErrorMessages.get(errorCode, alertMessage)
                    this.alertType = 'error'
                    this.showAlert = true
                    setTimeout(() =>
                    {
                        this.showAlert = false
                    }, 5000)
                }
            })
        },
        nextStep()
        {
            let obj = {
                correlationID: Math.floor(1000 + Math.random() * 9000).toString(),
                messageStamp: moment(new Date()).format('DD/MM/yyyy HH:mm:ss'),
                sysId: new DeviceUUID().get(),
                sysAuth: store.get('requestKeys/accessToken'),
                requestorID: localStorage.getItem('userName'),
                idSeed: sharedServices.getIdSeed(),
                custSerNo: store.get('requestKeys/ClientCode'),
                cardSserno: this.cardDetail.primeCardSerno,
                authCode: this.lastFiveDigit,
                activateDeActivate: 'D',
            }

            soapServices.temporarySuspendCard(obj).then(soapResp =>
            {
                if (soapResp?.ActDeActCardResponse?.ActDeActCardResult?.Resp?.Result?.ErrorCode == '000')
                {
                    let alertMessage = soapResp?.ActDeActCardResponse?.ActDeActCardResult?.Resp?.Result?.ErrorDesc
                    let errorCode = soapResp?.ActDeActCardResponse?.ActDeActCardResult?.Resp?.Result?.ErrorCode
                    this.alertMessage = soapErrorMessages.get(errorCode, alertMessage)
                    this.alertType = 'success'
                    this.showAlert = false
                    this.step = 2
                    window.scrollTo(0, 0)
                    setTimeout(() =>
                    {
                        this.showAlert = false
                    }, 5000)
                    
                    logEvent(this.analytics, 'AMEX_CI_BLOCK_CARD_SUCCESS', {
                        content_type: 'userName',
                        content_id: store.get('requestKeys/userName')
                    })
                } else
                {
                    logEvent(this.analytics, 'AMEX_CI_BLOCK_CARD_FAILURE', {
                        content_type: 'userName',
                        content_id: store.get('requestKeys/userName')
                    })

                    let alertMessage = soapResp?.ActDeActCardResponse?.ActDeActCardResult?.Resp?.Result?.ErrorDesc
                    let errorCode = soapResp?.ActDeActCardResponse?.ActDeActCardResult?.Resp?.Result.ErrorCode
                    this.alertMessage = soapErrorMessages.get(errorCode, alertMessage)
                    this.alertType = 'error'
                    this.showAlert = true
                    setTimeout(() =>
                    {
                        this.showAlert = false
                    }, 5000)
                }
            })
        },

        done()
        {
            router.push('/home')
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/auth.scss';

// .referesh-btn {
//     background: #D9D9D9;
//     padding: 7px;
//     margin-left: 8px;
//     margin-top: -1px;
//     border-radius: 6px;
// }

.v-application.theme--light .v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}

@media screen and (max-width: 1263px) {
    .divide {
        display: none;
    }
}

.v-btn--round {
    border-radius: 0px !important;
}

.secure-img {
    background: #e2f1fd;
    width: 123px;
    height: 123px;
    padding-top: 33px;
    border-radius: 79px;
    margin: 0 auto;
}

.suspended {
    background: rgba(237, 28, 36, 0.15);
    color: #d92d20;
    width: 126px;
    font-size: 10px;
}

.submit {
    width: 31%;
}

@media screen and (max-width: 800px) {
    .line-section {
        display: none;
    }
}</style>
;
